import React, { useState } from 'react';
import styles from './FileUpload.module.css';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';

const FileUpload = ({ onUpload }) => {
    const [files, setFiles] = useState([]);

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        onUpload(newFiles);
    };

    const handleRemoveFile = (fileName) => {
        setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
    };

    const handleUploadClick = () => {
        document.getElementById('file-upload').click();
    };

    return (
        <div className={styles.uploadContainer}>
            <input
                id="file-upload"
                type="file"
                multiple
                onChange={handleFileChange}
                className={styles.hiddenInput}
            />
            <ButtonWithIcon
                iconName="upload"
                iconPosition="left"
                className="uploadbutton"
                onClick={handleUploadClick}
            >
                Upload bestand
            </ButtonWithIcon>

            {files.map((file, index) => (
                <div key={index} className={styles.fileDisplay}>
                    <span className={styles.fileIcon}></span>
                    <span className={styles.fileName}>{file.name}</span>
                    <span className={styles.fileSize}>{(file.size / 1024 / 1024).toFixed(2)} MB</span>
                    <button onClick={() => handleRemoveFile(file.name)} className={styles.removeButton}>
                        <img src="/icons/x.svg" alt="Remove file" />
                    </button>
                </div>
            ))}
        </div>
    );
};

export default FileUpload;
