import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BasePage from './BasePage';
import PageHeader from '../components/PageHeader/PageHeader';
import BasicTable from '../components/Table/BasicTable';

const VerkoopContractenPage = () => {
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await fetch('/data/verkoopcontracten.json');
      const data = await response.json();

      if (data.projects && Array.isArray(data.projects)) {
        setRows(data.projects.map((project) => ({
          ...project,
          url: `/verkoopcontracten/${project.projectNumber}`
        })));
      } else {
        setRows([]);
        setError(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setRows([]);
      setError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const breadcrumbs = [
    { label: 'Verkoopcontracten', path: '/verkoopcontracten' }
  ];

  return (
    <BasePage>
      <PageHeader 
        title="Verkoopcontracten" 
        supportingText="Hieronder ziet u een lijst met uw aangemaakte projecten. Vanuit deze projecten kunt u bestellingen maken met leveranciers waar u een overeenkomst mee heeft afgesloten voor specifieke woningtypes."
        breadcrumbs={breadcrumbs} 
        variant="basic"
        showTabs={false}
        primaryButton={{ 
          label: 'Nieuwe verkoopcontract', 
          iconName: 'add', 
          onClick: () => navigate('/verkoopcontracten/nieuw')
        }}
        showDivider={false}  
      />

      <BasicTable
        rows={rows}
        headerTitle="Projecten"
        columns={[
          { header: 'Naam project', field: 'name', type: 'text' },
          { header: 'Projectnummer', field: 'projectNumber', type: 'text' },
          { header: 'Locatie', field: 'location', type: 'text' },
          { header: 'Startdatum', field: 'startDate', type: 'date' },
          { header: 'Verwachte opleverdatum', field: 'expectedCompletionDate', type: 'date' },
          { header: 'Status project', field: 'status', type: 'badge' }
        ]}
        error={error}
        isSortable={false}
      />
    </BasePage>
  );
};

export default VerkoopContractenPage;
