import React, { useState, useRef, useEffect } from 'react';
import styles from './Dropdown.module.css';
import style from '../Globals/Styles.module.css';
import { ReactComponent as ChevronDown } from '../../icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../icons/chevron-up.svg';

const Dropdown = ({ label, options, hint, placeholder = "Kies een optie"}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleOptionClick = value => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      {label && <label className={`${styles.labelCustom} ${style.textSmSemibold}`}>{label}</label>}
      <div 
        className={`${styles.dropdown} ${isOpen ? styles.open : ''}`}
        onClick={() => setIsOpen(!isOpen)}
        tabIndex="0"
      >
        <div className={styles.selected}>
          <span className={`${selectedOption ? style.textMdMedium : styles.placeholder} ${style.textMdRegular}`}>
            {selectedOption ? selectedOption.label : placeholder}
          </span>
          <div className={styles.icon}>
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </div>
        </div>
      </div>
      {isOpen && (
        <ul className={styles.optionsList}>
          {options.map(option => (
            <li 
              key={option.value} 
              onClick={() => handleOptionClick(option)}
              className={`${styles.option} ${option.value === selectedOption?.value ? styles.selected : ''} ${style.textMdMedium}`}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
      {hint && <p className={`${styles.hintCustom} ${style.textSmRegular}`}>{hint}</p>}
    </div>
  );
};

export default Dropdown;
