import React from 'react';
import { useNavigate } from 'react-router-dom';
import BasePage from './BasePage';
import PageHeader from '../components/PageHeader/PageHeader';
import ButtonWithIcon from '../components/Buttons/ButtonWithIcon';
import style from '../components/Globals/Styles.module.css';

const ContactPage = () => {
  const navigate = useNavigate();

  const handleNavigateToApiTest = () => {
    navigate('/api-test');
  };

  const breadcrumbs = [
    { label: 'Service & contact', path: '/contact' }
  ];

  return (
    <BasePage>
      <PageHeader 
        title="Service & contact" 
        supportingText="U ziet hier service & contact."
        breadcrumbs={breadcrumbs} 
        variant="basic"
        showTabs={false}
        showDivider={false}  
      />

      <div className={style.successContainer}>
        <ButtonWithIcon 
          iconName="link" 
          iconPosition="left" 
          className="alertLink"
          onClick={handleNavigateToApiTest}
        >
          API Check
        </ButtonWithIcon>
      </div>
    </BasePage>
  );
};

export default ContactPage;
