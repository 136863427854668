import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AccountPage from './pages/AccountPage';
import OvereenkomstenPage from './pages/OvereenkomstenPage';
import DemoPage from './pages/DemoPage';
import ContactPage from './pages/ContactPage';
import MyPropertiesPage from './pages/MyPropertiesPage';
import WoningtypeDetailPage from './pages/WoningtypeDetailPage';
import VerkoopContractenPage from './pages/VerkoopContractenPage';
import VerkoopContractDetailPage from './pages/VerkoopContractDetailPage';
import NieuwVerkoopovereenkomstPage from './pages/NieuwVerkoopovereenkomstPage';
import GegevensControlePage from './pages/GegevensControlePage';
import SuccessPage from './pages/SuccessPage';
import APITestPage from './pages/APITestPage';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/woningtypes" element={<MyPropertiesPage />} />
          <Route path="/woningtypes/:id" element={<WoningtypeDetailPage />} />
          <Route path="/verkoopcontracten" element={<VerkoopContractenPage />} />
          <Route path="/verkoopcontracten/:id" element={<VerkoopContractDetailPage />} />
          <Route path="/leveringsovereenkomsten" element={<OvereenkomstenPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/demo" element={<DemoPage />} />
          <Route path="/api-test" element={<APITestPage />} />
          <Route path="/verkoopcontracten/nieuw" element={<NieuwVerkoopovereenkomstPage />} />
          <Route path="/verkoopcontracten/nieuw/controle" element={<GegevensControlePage />} />
          <Route path="/verkoopcontracten/succes" element={<SuccessPage />} />

        </Routes>
      </div>
    </Router>
  );
};

export default App;
