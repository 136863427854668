import React, { useState } from 'react';
import styles from './TextAreaField.module.css';  // Zorg ervoor dat je een nieuwe CSS module aanmaakt of de bestaande aanpast
import style from '../Globals/Styles.module.css';

const TextAreaField = ({
  label, 
  hint, 
  placeholder = "Enter text", 
  error, 
  disabled = false, 
  rows = 5
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState("");

  return (
    <div className={styles.textAreaContainer}>
      {label && <label className={`${styles.labelCustom} ${style.textSmSemibold}`}>{label}</label>}
      <div className={`${styles.textAreaWrapper} ${error ? styles.error : ''} ${isFocused ? styles.focused : ''}`}>
        <textarea
          className={`${styles.textarea} ${style.textMdRegular}`}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          disabled={disabled}
          rows={rows}
        />
      </div>
      {error ? (
        <p className={`${styles.hintCustom} ${style.textSmRegular} ${styles.errorText}`}>{error}</p>
      ) : (
        <p className={`${styles.hintCustom} ${style.textSmRegular}`}>{hint}</p>
      )}
    </div>
  );
};

export default TextAreaField;
