import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import style from '../Globals/Styles.module.css';
import logo from '../../assets/images/LogoFooter.webp';

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContent}>
        <Link to="/">
          <img src={logo} alt="Go to Homepage" className={styles.logo} />
        </Link>
        <p className={`${styles.rightsText} ${style.textMdRegular}`}>
          &copy; {new Date().getFullYear()} All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;