import React from 'react';
import BasePage from './BasePage';
import PageHeader from '../components/PageHeader/PageHeader';

const AccountPage = () => {

  const breadcrumbs = [
    { label: 'Mijn account', path: '/account' }
  ];

  return (
    <BasePage>
     <PageHeader 
        title="Mijn account" 
        supportingText="U ziet hier mijn account." 
        breadcrumbs={breadcrumbs} 
        variant="basic"
        showTabs={false}
        showDivider={false}  
      />

    </BasePage>
  );
};

export default AccountPage;