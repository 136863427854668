import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Table.module.css';
import style from '../Globals/Styles.module.css';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import SearchField from '../Input/SearchField';
import Badge from '../Badge/Badge';
import Dropdown from '../Input/Dropdown';
import { ReactComponent as TrashIcon } from '../../icons/trash-01.svg';
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down.svg';
import { ReactComponent as FilterIcon } from '../../icons/filter-lines.svg';
import { ReactComponent as SearchIcon } from '../../icons/search-lg.svg';
import { ReactComponent as AlertIcon } from '../../icons/alert-circle.svg';

const Table = ({ rows, error, isBasic = false, isDropdown = false, headerTitle = 'Table title 1' }) => {
  const [sortedRows, setSortedRows] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRows, setExpandedRows] = useState([]); // State to track expanded rows
  const navigate = useNavigate();

  useEffect(() => {
    // Filter rows based on the search term
    let filteredRows = rows.filter(row =>
      row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (row.subtitle && row.subtitle.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    // Sort the filtered rows
    if (sortConfig.key) {
      filteredRows = filteredRows.sort((a, b) => {
        const { key, direction } = sortConfig;

        if (key === 'status') {
          const statusOrder = ['needed', 'success', 'unsuccessful'];
          const aStatusIndex = statusOrder.indexOf(a.status);
          const bStatusIndex = statusOrder.indexOf(b.status);

          if (aStatusIndex === -1 || bStatusIndex === -1) return 0;
          return direction === 'ascending' ? aStatusIndex - bStatusIndex : bStatusIndex - aStatusIndex;
        } else {
          if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
          if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
          return 0;
        }
      });
    }

    setSortedRows(filteredRows);
  }, [rows, searchTerm, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
      direction = 'ascending';
    } else {
      direction = 'ascending';
    }
    setSortConfig({ key, direction });
  };

  const handleRowClick = (row) => {
    if (isBasic && row.url) {
      navigate(row.url);
    }
  };

  const toggleRowExpand = (index) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <ChevronUpIcon className={styles.sortIcon} /> : <ChevronDownIcon className={styles.sortIcon} />;
    }
    return <ChevronDownIcon className={styles.sortIcon} />;
  };

  const renderControlBar = () => (
    <div className={styles.contentBlock}>
      <div className={styles.leftContent}>
        <SearchField placeholder="Zoeken..." onChange={(e) => setSearchTerm(e.target.value)} />
      </div>
      <div className={styles.rightContent}>
        <Dropdown
          label=""
          options={[
            { value: 'title', label: 'Sorteren op titel' },
            { value: 'date', label: 'Sorteren op datum' },
            { value: 'status', label: 'Sorteren op status' }
          ]}
          placeholder="Sorteren op..."
        />
        <ButtonWithIcon className="buttonSecondaryGrayXL" iconName="filter" iconPosition="left">
          Filteren
        </ButtonWithIcon>
      </div>
    </div>
  );

  if (error) {
    return (
      <div className={styles.tableContainer}>
        {renderControlBar()}
        <div className={styles.emptyStateContainer}>
          <div className={styles.iconCircleError}>
            <AlertIcon className={styles.alertIcon} />
          </div>
          <p className={`${style.textMdSemibold} ${styles.noContentTitle}`}>Er is iets mis gegaan...</p>
          <p className={`${style.textSmRegular} ${styles.noContentSubtitle}`}>
            We hebben problemen met het laden van de pagina. <br /> Ververs de pagina of neem eventueel contact met ons op.
          </p>
          <div className={styles.buttonGroup}>
            <ButtonWithIcon className="buttonSecondaryGrayXL">Contact</ButtonWithIcon>
            <ButtonWithIcon className="buttonPrimaryXL">Ververs pagina</ButtonWithIcon>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.tableContainer}>
      {renderControlBar()}
      {sortedRows.length === 0 ? (
        <div className={styles.emptyStateContainer}>
          <div className={styles.iconCircle}>
            <SearchIcon className={styles.searchIcon} />
          </div>
          <p className={`${style.textMdSemibold} ${styles.noContentTitle}`}>Geen projecten gevonden</p>
          <p className={`${style.textSmRegular} ${styles.noContentSubtitle}`}>Voeg hier een nieuw project toe.</p>
          <ButtonWithIcon className="buttonPrimaryXL">Nieuw project aanmaken</ButtonWithIcon>
        </div>
      ) : (
        <table className={styles.table}>
          <thead className={styles.tableHeader}>
            <tr>
              <th className={`${style.textXsMedium} ${styles.headerCell}`} onClick={() => handleSort('title')}>
                {isBasic ? headerTitle : 'Table title 1'} {getSortIcon('title')}
              </th>
              {!isBasic && (
                <>
                  <th className={`${style.textXsMedium} ${styles.headerCell}`} onClick={() => handleSort('amount')}>
                    Aantal {getSortIcon('amount')}
                  </th>
                  <th className={`${style.textXsMedium} ${styles.headerCell}`} onClick={() => handleSort('tableTitle2')}>
                    Table title 2 {getSortIcon('tableTitle2')}
                  </th>
                  <th className={`${style.textXsMedium} ${styles.headerCell}`} onClick={() => handleSort('date')}>
                    Datum {getSortIcon('date')}
                  </th>
                  <th className={`${style.textXsMedium} ${styles.headerCell}`}>
                    Link
                  </th>
                  <th className={`${style.textXsMedium} ${styles.headerCell}`} onClick={() => handleSort('status')}>
                    Status {getSortIcon('status')}
                  </th>
                </>
              )}
              <th className={styles.headerCell}></th>
            </tr>
          </thead>
          <tbody>
            {sortedRows.map((row, index) => (
              <>
                <tr
                  key={index}
                  className={styles.tableRow}
                  onClick={() => isBasic && handleRowClick(row)} // Alleen klikbaar in de basic variant
                >
                  <td
                    className={styles.cell}
                    colSpan={isBasic || isDropdown ? 7 : 1} // Gebruik colspan om de cel uit te breiden in de basic variant
                  >
                    <div className={style.textSmMedium} style={{ color: '#101828' }}>{row.title}</div>
                    {!isBasic && !isDropdown && (
                      <div className={style.textSmRegular} style={{ color: '#475467' }}>{row.subtitle}</div>
                    )}
                  </td>
                  {isDropdown && (
                    <td className={styles.cell}>
                      {expandedRows.includes(index) ? (
                        <ChevronUpIcon className={styles.chevronIcon} onClick={() => toggleRowExpand(index)} />
                      ) : (
                        <ChevronDownIcon className={styles.chevronIcon} onClick={() => toggleRowExpand(index)} />
                      )}
                    </td>
                  )}
                  {!isBasic && !isDropdown && (
                    <>
                      <td className={`${style.textSmRegular} ${styles.cell}`}>{row.amount}</td>
                      <td className={`${style.textSmRegular} ${styles.cell}`}>{row.tableTitle2}</td>
                      <td className={`${style.textSmRegular} ${styles.cell}`}>{row.date}</td>
                      <td className={styles.cell}>
                        <ButtonWithIcon className="tableLink">{row.linkText || 'Bekijk overeenkomst'}</ButtonWithIcon>
                      </td>
                      <td className={styles.cell}>
                        <Badge status={row.status} />
                      </td>
                    </>
                  )}
                </tr>
                {isDropdown && expandedRows.includes(index) && (
                  <tr className={styles.expandedRow}>
                    <td colSpan={7} className={styles.expandedContent}>
                      {/* Hergebruik de Advanced Table header en rijen */}
                      <table className={styles.innerTable}>
                        <thead className={styles.tableHeader}>
                          <tr>
                            <th className={`${style.textXsMedium} ${styles.headerCell}`}>Table title 1</th>
                            <th className={`${style.textXsMedium} ${styles.headerCell}`}>Table title 2</th>
                            <th className={`${style.textXsMedium} ${styles.headerCell}`}>Link</th>
                            <th className={`${style.textXsMedium} ${styles.headerCell}`}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className={styles.innerTableRow}>
                            <td className={`${style.textSmRegular} ${styles.cell}`}>{row.title}</td>
                            <td className={`${style.textSmRegular} ${styles.cell}`}>{row.tableTitle2}</td>
                            <td className={styles.cell}>
                              <ButtonWithIcon className="tableLink">{row.linkText || 'Bekijk overeenkomst'}</ButtonWithIcon>
                            </td>
                            <td className={styles.cell}>
                              <Badge status={row.status} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Table;
