import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BasePage from './BasePage';
import PageHeader from '../components/PageHeader/PageHeader';
import Accordion from '../components/Accordion/Accordion';
import ButtonWithIcon from '../components/Buttons/ButtonWithIcon';
import { formatMultilineText } from '../components/utils/stringUtils';
import styles from './VerkoopContractDetailPage.module.css'; 
import style from '../components/Globals/Styles.module.css'; 
import Alert from '../components/Feedback/Alert';

const VerkoopContractDetailPage = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [activeTab, setActiveTab] = useState('projectgegevens');

  const fetchProjectData = async () => {
    try {
      const response = await fetch(`/data/verkoopcontracten.json`);
      const data = await response.json();
      const foundProject = data.projects.find((proj) => proj.projectNumber === id);
      if (foundProject) {
        setProject(foundProject);
      } else {
        console.error("Project niet gevonden");
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, [id]);

  const breadcrumbs = [
    { label: 'Verkoopcontracten', path: '/verkoopcontracten' },
    { label: project ? project.name : 'Laden...', path: `/verkoopcontracten/${id}` }
  ];

  const tabs = [
    { key: 'projectgegevens', label: 'Projectgegevens' },
    { key: 'woningtype', label: 'Woningtype' }
  ];

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  if (!project) {
    return <div>Laden...</div>;
  }

  return (
    <BasePage backgroundColor="#F9FAFB">
      <PageHeader 
        title={project.name} 
        breadcrumbs={breadcrumbs} 
        primaryButton={{ label: 'Bestellen', iconName: 'add' }}
        secondaryButton={{ label: 'Wijzig project', iconName: 'settings' }} 
        showTabs={true}
        tabs={tabs}
        onTabChange={handleTabChange}
        variant="basic"
        showDivider={false}
      />

      <div>
        {activeTab === 'projectgegevens' && (
          <div>
            <h2 className={style.displayXsBold}>Titel 1</h2>
            {/* Eerste accordion */}
            <Accordion title="Overeenkomstgegevens" grouped={true}>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Overeenkomstnummer</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.contractDetails?.contractNumber || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Overeenkomst datum</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.contractDetails?.contractDate || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Type contract</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.contractDetails?.contractType || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Woningtype</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>
                  <ButtonWithIcon
                    iconName="right"
                    iconPosition="right"
                    className="buttonLinkColorXL"
                    onClick={() => window.location.href = project.contractDetails?.housingType?.link || '#'}
                  >
                    {project.contractDetails?.housingType?.title || 'Geen gegevens'}
                  </ButtonWithIcon>
                </div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Aantal woningen</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.contractDetails?.numberOfHouses || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Assetmanager</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>
                  {formatMultilineText(project.contractDetails?.assetManager)}
                </div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Opdrachtgever</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>
                  {project.contractDetails?.client 
                    ? formatMultilineText(project.contractDetails.client) 
                    : 'Geen gegevens'}
                </div>
              </div>
            </Accordion>

            {/* Algemene voorwaarden titels hardcoded, beschrijvingen uit JSON */}
            <Accordion title="Algemene voorwaarden" grouped={false}>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Leveringstermijnen en transport</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.leveringstermijnenEnTransport || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Kwaliteitseisen</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.kwaliteitseisen || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Garantie- en Servicevoorwaarden</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.garantieEnServicevoorwaarden || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Betalingsschema en -voorwaarden</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.betalingsschemaEnVoorwaarden || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Vertrouwelijkheid en Gegevensbescherming</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.vertrouwelijkheidEnGegevensbescherming || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Aansprakelijkheid en Vrijwaring</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.aansprakelijkheidEnVrijwaring || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Intellectueel Eigendom</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.intellectueelEigendom || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Verzekering en dekking</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.verzekeringEnDekking || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Wijzigingsbeheer</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.wijzigingsbeheer || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Beëindigingsvoorwaarden</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.beeindigingsvoorwaarden || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Geschillenbeslechting</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.geschillenbeslechting || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Force Majeure</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.forceMajeure || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Boeteclausules</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.boeteclausules || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Duur van het Contract</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.duurVanHetContract || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Total Cost of Ownership (TCO)</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project.generalConditions?.totalCostOfOwnership || 'Geen gegevens'}</div>
              </div>
            </Accordion>

            <h2 className={style.displayXsBold}>Titel 2</h2>
            {/* Nieuwe accordion "Projectinformatie" */}
            <Accordion title="Projectinformatie" grouped={true}>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Project naam</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.name || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Projectnummer</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.projectNumber || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Projectbudget</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.projectBudget || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Startdatum project</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.startDate || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Verwachte opleverdatum</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.expectedCompletionDate || 'Geen gegevens'}</div>
              </div>
            </Accordion>

            {/* Accordion "Beheerder informatie (optioneel)" */}
            <Accordion title="Beheerder informatie (optioneel)" grouped={true}>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Beheerder</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.manager?.name || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Contactpersoon</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.manager?.contactPerson || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Adres</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.manager?.address || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Telefoonnummer</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.manager?.phone || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Email</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.manager?.email || 'Geen gegevens'}</div>
              </div>
            </Accordion>

            {/* Accordion "Bouwlocatie van project" */}
            <Accordion title="Bouwlocatie van project" grouped={true}>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Bedrijfsnaam</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.constructionLocation?.companyName || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Adres</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.constructionLocation?.address || 'Geen gegevens'}</div>
              </div>
            </Accordion>

            {/* Accordion "Assemblage locatie" */}
            <Accordion title="Assemblage locatie" grouped={true}>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Bedrijfsnaam</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.assemblyLocation?.companyName || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Adres</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.assemblyLocation?.address || 'Geen gegevens'}</div>
              </div>
            </Accordion>

            {/* Accordion "Woningspecificaties" */}
            <Accordion title="Woningspecificaties" grouped={true}>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Opties voor de woning</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.housingSpecifications?.options || 'Geen gegevens'}</div>
              </div>
            </Accordion>

            {/* Accordion "Overige informatie" */}
            <Accordion title="Overige informatie" grouped={true}>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Opmerkingen</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.otherInfo?.remarks || 'Geen gegevens'}</div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Risicoanalyse</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>{project?.otherInfo?.riskAnalysis || 'Geen gegevens'}</div>
              </div>
            </Accordion>

            {/* Accordion "Documenten" */}
            <Accordion title="Documenten" grouped={false}>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Projectplan</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>
                  {project?.documents?.projectPlan 
                    ? <a href={project.documents.projectPlan} target="_blank" rel="noopener noreferrer">Download Projectplan</a>
                    : 'Geen gegevens'}
                </div>
              </div>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Bouwplan</div>
                <div className={`${style.textMdRegular} ${styles.value}`}>
                  {project?.documents?.constructionPlan 
                    ? <a href={project.documents.constructionPlan} target="_blank" rel="noopener noreferrer">Download Bouwplan</a>
                    : 'Geen gegevens'}
                </div>
              </div>
            </Accordion>

            <h2 className={style.displayXsBold}>Titel 3</h2>

            {/* Accordion "Ondertekening" */}
            <Accordion title="Ondertekening" grouped={false}>
              <div className={styles.row}>
                <div className={`${style.textMdSemibold} ${styles.title}`}>Opdrachtgever</div>
                <div className={`${styles.alertContainer}`}>
                  {/* Gebruik de alert component met een status, bijvoorbeeld 'success' */}
                  <Alert 
                    type={project?.status || 'info'} 
                    title="Status opdrachtgever" 
                    description={project?.signing?.clientDescription || 'Geen status beschikbaar'} 
                  />
                </div>
              </div>
            </Accordion>


          </div>
        )}
        {activeTab === 'woningtype' && (
          <div>
           <h2 className={style.displayXsBold}>Woningtype-informatie</h2>
            {/* Voeg hier de woningtype-informatie toe */}
          </div>
        )}
      </div>
    </BasePage>
  );
};

export default VerkoopContractDetailPage;
