import React, { useState } from 'react';
import styles from './InputField.module.css';  // Zorg ervoor dat je een nieuwe CSS module aanmaakt of de bestaande aanpast
import style from '../Globals/Styles.module.css';
import { ReactComponent as HelpIcon } from '../../icons/help-circle.svg';
import { ReactComponent as AlertIcon } from '../../icons/alert-circle.svg';

const InputField = ({
  label, 
  hint, 
  placeholder = "Enter text", 
  error, 
  disabled = false, 
  help = false
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState("");

  return (
    <div className={styles.inputContainer}>
      {label && <label className={`${styles.labelCustom} ${style.textSmSemibold}`}>{label}</label>}
      <div className={`${styles.inputWrapper} ${error ? styles.error : ''} ${isFocused ? styles.focused : ''}`}>
        <input
          className={`${styles.input} ${value ? style.textMdRegular : style.textMdRegular}`}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          disabled={disabled}
        />
        {help && !error && <HelpIcon className={`${styles.icon} ${styles.helpIcon}`} />}
        {error && <AlertIcon className={`${styles.icon} ${styles.alertIcon}`} />}
      </div>
      {error ? (
        <p className={`${styles.hintCustom} ${style.textSmRegular} ${styles.errorText}`}>{error}</p>
      ) : (
        <p className={`${styles.hintCustom} ${style.textSmRegular}`}>{hint}</p>
      )}
    </div>
  );
};

export default InputField;
