import React, { useState } from 'react';
import styles from './DropdownTable.module.css';
import style from '../Globals/Styles.module.css';
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down.svg';
import Badge from '../Badge/Badge';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import SearchFilterBar from '../SearchFilterBar/SearchFilterBar';
import { ReactComponent as SearchIcon } from '../../icons/search-lg.svg';
import { ReactComponent as AlertIcon } from '../../icons/alert-circle.svg';

const DropdownTable = ({ 
  rows, 
  error = false, 
  showRadioButtons = false, 
  radioOptions = [], 
  selectedRadio, 
  onRadioChange,
  searchPlaceholder = "Zoeken..."
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleRowClick = (index) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  // Zoek in meerdere velden zoals title, productName, articleNumber, en supplierName
  const filteredRows = rows.filter((row) => {
    const isInTitle = row.title.toLowerCase().includes(searchTerm.toLowerCase());

    // Doorloop alle details in elke rij en controleer de zoekterm
    const isInDetails = row.details.some((detail) => 
      detail.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      detail.articleNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      detail.supplierName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return isInTitle || isInDetails;
  });

  const renderEmptyState = () => (
    <div className={styles.emptyStateContainer}>
      <div className={styles.iconCircle}>
        <SearchIcon className={styles.searchIcon} />
      </div>
      <p className={`${style.textMdSemibold} ${styles.noContentTitle}`}>Geen resultaten gevonden</p>
      <p className={`${style.textSmRegular} ${styles.noContentSubtitle}`}>Voeg hier een nieuwe titel toe.</p>
      <ButtonWithIcon className="buttonPrimaryXL">Nieuw item toevoegen</ButtonWithIcon>      
    </div>
  );

  const renderErrorState = () => (
    <div className={styles.errorStateContainer}>
      <div className={styles.iconCircleError}>
        <AlertIcon className={styles.alertIcon} />
      </div>
      <p className={`${style.textMdSemibold} ${styles.noContentTitle}`}>Er is iets mis gegaan...</p>
      <p className={`${style.textSmRegular} ${styles.noContentSubtitle}`}>
        We hebben problemen met het laden van de gegevens.
        <br /> Probeer het opnieuw of neem contact met ons op.
      </p>
      <div className={styles.buttonContainer}>
        <ButtonWithIcon className="buttonSecondaryGrayXL">Contact</ButtonWithIcon>
        <ButtonWithIcon className="buttonPrimaryXL">Ververs pagina</ButtonWithIcon>
      </div>
    </div>
  );

  return (
    <div>
      <SearchFilterBar
        onSearch={setSearchTerm}
        searchPlaceholder={searchPlaceholder}
        sortOptions={[{ value: 'title', label: 'Sorteren op titel' }]}
        showRadioButtons={showRadioButtons}  
        radioOptions={radioOptions}          
        selectedRadio={selectedRadio}        
        onRadioChange={onRadioChange}        
      />
      <div className={styles.tableContainer}>
        {error ? (
          renderErrorState()
        ) : filteredRows.length === 0 ? (
          renderEmptyState()
        ) : (
          <table className={styles.table}>
            <tbody>
              {filteredRows.map((row, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={styles.tableRow}
                    onClick={() => handleRowClick(index)}
                  >
                    <td className={styles.cell}>
                      <div className={style.textMdSemibold} style={{ color: '#101828' }}>
                        {row.title}
                      </div>
                    </td>
                    <td className={styles.cellIcon}>
                      {expandedRows.includes(index) ? (
                        <ChevronUpIcon className={styles.chevronIcon} />
                      ) : (
                        <ChevronDownIcon className={styles.chevronIcon} />
                      )}
                    </td>
                  </tr>
                  {expandedRows.includes(index) && (
                    <tr className={styles.expandedRow}>
                      <td colSpan={2} className={styles.expandedContent}>
                        {row.details && row.details.length > 0 ? (
                          <table className={styles.subTable}>
                            <thead>
                              <tr>
                                <th className={style.textSmRegular}>Leveringsovereenkomst met leverancier</th>
                                <th className={style.textSmRegular}>Overeenkomstnummer</th>
                                <th className={style.textSmRegular}>Overzicht overeenkomst</th>
                                <th className={style.textSmRegular}>Status overeenkomst</th>
                              </tr>
                            </thead>
                            <tbody>
                              {row.details.map((detail, idx) => (
                                <tr key={idx} className={styles.subTableRow}>
                                  <td>
                                    <div className={style.textSmBold}>{detail.productName}</div>
                                    <div className={style.textSmRegular}>Artikelnummer: {detail.articleNumber}</div>
                                    <div className={style.textSmRegular}>Leverancier: {detail.supplierName}</div>
                                  </td>
                                  <td className={style.textSmRegular}>{detail.contractNumber}</td>
                                  <td>
                                    <ButtonWithIcon className="tableLink">
                                      {detail.linkText || 'Bekijk overeenkomst'}
                                    </ButtonWithIcon>
                                  </td>
                                  <td>
                                    <Badge status={detail.status} />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : row.error ? (
                          renderErrorState() 
                        ) : (
                          renderEmptyState()
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DropdownTable;
