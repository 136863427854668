import React, { useState } from 'react';
import styles from './Accordion.module.css';  
import style from '../Globals/Styles.module.css'; 
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up.svg';

const Accordion = ({ title, children, grouped = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${styles.accordion} ${grouped ? styles.grouped : styles.loose}`}>
      <div className={styles.header} onClick={toggleAccordion}>
        <h3 className={`${style.textLgSemibold} ${styles.title}`}>{title}</h3>  
        {isOpen ? <ChevronUpIcon className={styles.chevronIcon} /> : <ChevronDownIcon className={styles.chevronIcon} />}
      </div>
      {isOpen && (
        <div className={styles.content}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;
