import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BasicTable.module.css';
import style from '../Globals/Styles.module.css';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import { ReactComponent as SearchIcon } from '../../icons/search-lg.svg';
import { ReactComponent as AlertIcon } from '../../icons/alert-circle.svg';
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down.svg';
import SearchFilterBar from '../SearchFilterBar/SearchFilterBar';
import Badge from '../Badge/Badge';

const BasicTable = ({ rows, headerTitle = 'Title', columns = [], error = false, isSortable = false, searchPlaceholder = "Zoeken", showRadioButtons = false, radioOptions = [] }) => {
  const [sortedRows, setSortedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(columns.length > 0 ? columns[0].field : 'title');
  const [selectedRadio, setSelectedRadio] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!error) {
      let filteredRows = rows.filter(row =>
        columns.length > 0
          ? columns.some(column => row[column.field]?.toLowerCase().includes(searchTerm.toLowerCase()))
          : row.title?.toLowerCase().includes(searchTerm.toLowerCase()) // Fallback voor 1 kolom
      );

      if (isSortable) {
        filteredRows = filteredRows.sort((a, b) => {
          if (sortOrder === 'asc') {
            return a[sortColumn]?.localeCompare(b[sortColumn]);
          } else {
            return b[sortColumn]?.localeCompare(a[sortColumn]);
          }
        });
      }

      setSortedRows(filteredRows);
    }
  }, [rows, searchTerm, error, sortOrder, sortColumn, isSortable, selectedRadio, columns]);

  const handleRowClick = (row) => {
    if (row.url) {
      navigate(row.url);
    }
  };

  const handleSort = (field) => {
    if (sortColumn === field) {
      // Wissel sorteer volgorde als dezelfde kolom wordt geselecteerd
      setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      // Zet nieuwe sorteer kolom en begin met de omgekeerde volgorde van de huidige sortering
      setSortColumn(field);
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }
  };
  
  const renderEmptyState = () => (
    <div className={styles.emptyStateContainer}>
      <div className={styles.iconCircle}>
        <SearchIcon className={styles.searchIcon} />
      </div>
      <p className={`${style.textMdSemibold} ${styles.noContentTitle}`}>Geen resultaten gevonden</p>
      <p className={`${style.textSmRegular} ${styles.noContentSubtitle}`}>Voeg hier een nieuwe titel toe.</p>
      <ButtonWithIcon className="buttonPrimaryXL">Nieuw item toevoegen</ButtonWithIcon>
    </div>
  );

  const renderErrorState = () => (
    <div className={styles.emptyStateContainer}>
      <div className={styles.iconCircleError}>
        <AlertIcon className={styles.alertIcon} />
      </div>
      <p className={`${style.textMdSemibold} ${styles.noContentTitle}`}>Er is iets mis gegaan...</p>
      <p className={`${style.textSmRegular} ${styles.noContentSubtitle}`}>
        We hebben problemen met het laden van de gegevens. <br /> Probeer het opnieuw of neem contact met ons op.
      </p>
      <ButtonWithIcon className="buttonPrimaryXL">Opnieuw proberen</ButtonWithIcon>
    </div>
  );

  const getSortIcon = (field) => {
    // Toon het juiste sorteericoon en benadruk de actieve kolom
    if (sortColumn === field) {
      return sortOrder === 'asc' ? (
        <ChevronUpIcon className={`${styles.sortIcon} ${styles.activeSortIcon}`} />
      ) : (
        <ChevronDownIcon className={`${styles.sortIcon} ${styles.activeSortIcon}`} />
      );
    } else {
      // Toon altijd een sorteericoon bij alle kolommen
      return <ChevronUpIcon className={styles.sortIcon} style={{ opacity: 0.5 }} />;
    }
  };

  return (
    <div>
      <SearchFilterBar
        onSearch={setSearchTerm}
        searchPlaceholder={searchPlaceholder}
        sortOptions={[{ value: 'title', label: 'Sorteren op titel' }]}
        showRadioButtons={showRadioButtons}
        radioOptions={radioOptions}
        selectedRadio={selectedRadio}
        onRadioChange={setSelectedRadio}
      />
      <div className={styles.tableContainer}>
        {error ? (
          renderErrorState()
        ) : sortedRows.length === 0 ? (
          renderEmptyState()
        ) : (
          <table className={styles.table}>
            <thead className={styles.tableHeader}>
              <tr>
                {columns.length > 0 ? (
                  columns.map((col, index) => (
                    <th
                      key={index}
                      className={`${style.textXsMedium} ${styles.headerCell}`}
                      onClick={isSortable ? () => handleSort(col.field) : null} // Sorteren op deze kolom
                    >
                      {col.header}
                      {isSortable && getSortIcon(col.field)}
                    </th>
                  ))
                ) : (
                  <th className={`${style.textXsMedium} ${styles.headerCell}`} onClick={isSortable ? () => handleSort('title') : null}>
                    {headerTitle}
                    {isSortable && getSortIcon('title')}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {sortedRows.map((row, index) => (
                <tr key={index} className={styles.tableRow} onClick={() => handleRowClick(row)}>
                  {columns.length > 0 ? (
                    columns.map((col, colIndex) => (
                      <td key={colIndex} className={styles.cell}>
                        {col.type === 'badge' ? (
                          <Badge status={row[col.field]} />
                        ) : (
                          <div className={style.textSmRegular}>{row[col.field]}</div>
                        )}
                      </td>
                    ))
                  ) : (
                    <td className={styles.cell}>
                      <div className={style.textSmMedium} style={{ color: '#101828' }}>{row.title}</div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default BasicTable;
