import React from 'react';
import { useNavigate } from 'react-router-dom';

import BasePage from './BasePage';
import PageHeader from '../components/PageHeader/PageHeader';
import Accordion from '../components/Accordion/Accordion';
import ButtonWithIcon from '../components/Buttons/ButtonWithIcon';
import ProgressSteps from '../components/ProgressSteps/ProgressSteps';
import styles from './GegevensControlePage.module.css';
import style from '../components/Globals/Styles.module.css';

const GegevensControlePage = ({ formData = {}, onGoBack }) => {
    const navigate = useNavigate(); // Hier definiëren we useNavigate
    
    const breadcrumbs = [
      { label: 'Verkoopcontracten', path: '/verkoopcontracten' },
      { label: 'Nieuw verkoopovereenkomst', path: '/verkoopcontracten/nieuw' },
      { label: 'Gegevens controle', path: '/verkoopcontracten/nieuw/controle' }
    ];

    const steps = ['Gegevens invullen', 'Gegevens controleren'];
    const currentStep = 1;

    const handleSubmit = () => {
      navigate('/verkoopcontracten/succes'); // Navigeren naar de succespagina
    };

    return (
      <BasePage backgroundColor="#F9FAFB">
        <PageHeader 
          title="Nieuw verkoopovereenkomst" 
          breadcrumbs={breadcrumbs}
          variant="basic"
          showTabs={false}
          showDivider={false}
          extraBottomPadding={true}
        />

        <div className={styles.stepsContainer}>
          <ProgressSteps 
            steps={steps} 
            currentStep={currentStep} 
          />
        </div>

        {/* Instructieblok met formBlock-styling */}
        <div className={styles.formBlock}>
          <h2 className={style.textXlSemibold}>Instructies</h2>
          <p className={style.textSmRegular}>Controleer uw gegevens.</p>
          <ButtonWithIcon 
            iconName="right" 
            iconPosition="left" 
            className="alertLink" 
            onClick={() => console.log('Meer informatie link geklikt!')}
          >
            Nog vragen? Kijk hier voor meer informatie
          </ButtonWithIcon>
        </div>

        <div>
          <h2 className={style.displayXsBold}>Titel 1</h2>
        
          {/* Overeenkomstgegevens */}
          <Accordion title="Overeenkomstgegevens" grouped={true}>
            <div className={styles.row}>
              <div className={`${style.textMdSemibold} ${styles.title}`}>Overeenkomstnummer</div>
              <div className={`${style.textMdRegular} ${styles.value}`}>{formData.contractNumber || 'Geen gegevens'}</div>
            </div>
            <div className={styles.row}>
              <div className={`${style.textMdSemibold} ${styles.title}`}>Overeenkomst datum</div>
              <div className={`${style.textMdRegular} ${styles.value}`}>{formData.contractDate || 'Geen gegevens'}</div>
            </div>
            <div className={styles.row}>
              <div className={`${style.textMdSemibold} ${styles.title}`}>Aantal woningen</div>
              <div className={`${style.textMdRegular} ${styles.value}`}>{formData.numberOfHouses || 'Geen gegevens'}</div>
            </div>
          </Accordion>

          {/* Projectinformatie */}
          <Accordion title="Projectinformatie" grouped={true}>
            <div className={styles.row}>
              <div className={`${style.textMdSemibold} ${styles.title}`}>Project naam</div>
              <div className={`${style.textMdRegular} ${styles.value}`}>{formData.projectName || 'Geen gegevens'}</div>
            </div>
            <div className={styles.row}>
              <div className={`${style.textMdSemibold} ${styles.title}`}>Projectnummer</div>
              <div className={`${style.textMdRegular} ${styles.value}`}>{formData.projectNumber || 'Geen gegevens'}</div>
            </div>
            <div className={styles.row}>
              <div className={`${style.textMdSemibold} ${styles.title}`}>Startdatum project</div>
              <div className={`${style.textMdRegular} ${styles.value}`}>{formData.startDate || 'Geen gegevens'}</div>
            </div>
          </Accordion>

          {/* Documenten */}
          <Accordion title="Documenten" grouped={false}>
            <div className={styles.row}>
              <div className={`${style.textMdSemibold} ${styles.title}`}>Projectplan</div>
              <div className={`${style.textMdRegular} ${styles.value}`}>{formData.documents?.projectPlan || 'Geen gegevens'}</div>
            </div>
            <div className={styles.row}>
              <div className={`${style.textMdSemibold} ${styles.title}`}>Bouwplan</div>
              <div className={`${style.textMdRegular} ${styles.value}`}>{formData.documents?.constructionPlan || 'Geen gegevens'}</div>
            </div>
          </Accordion>
        </div>

        {/* Knoppen */}
        <div className={styles.buttonContainer}>
          <ButtonWithIcon 
            className="buttonSecondaryGray2XL" 
            onClick={onGoBack} // Opnieuw gedefinieerd
          >
            Vorige
          </ButtonWithIcon>
          <ButtonWithIcon 
            className="buttonPrimary2XL" 
            onClick={handleSubmit} // Gebruik de interne submit-handler
          >
            Opslaan en versturen
          </ButtonWithIcon>
        </div>
      </BasePage>
    );
};

export default GegevensControlePage;
