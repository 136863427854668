import React, { useState } from 'react';
import BasePage from './BasePage'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../components/Globals/foundation.css';
import '../components/Globals/styles.css';
import Checkbox from '../components/Input/Checkbox'; 
import ButtonWithIcon from '../components/Buttons/ButtonWithIcon';
import styles from '../components/Globals/Styles.module.css';
import RadioButton from '../components/Input/RadioButton';
import Alert from '../components/Feedback/Alert';
import Dropdown from '../components/Input/Dropdown';
import SearchField from '../components/Input/SearchField';
import InputField from '../components/Input/InputField';
import TextAreaField from '../components/Input/TextAreaField'; 
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';  
import PageHeader from '../components/PageHeader/PageHeader';
import HorizontalTabs from '../components/Tabs/HorizontalTabs';
import HeaderNavigation from '../components/HeaderNavigation/HeaderNavigation';
import Footer from '../components/Footer/Footer'; 
import Badge from '../components/Badge/Badge';
import Table from '../components/Table/Table';
import LoginTest from '../LoginTest';
import RegisterForm from '../RegisterForm';






function App() {






  /*--CheckBoxes--*/
  const [termsChecked, setTermsChecked] = useState(false);
  const [newsletterChecked, setNewsletterChecked] = useState(false);
  const [notificationsChecked, setNotificationsChecked] = useState(false);

  /*--RadioButton--*/
  const [selectedOption, setSelectedOption] = useState('option11');

  const handleClick = () => { console.log('Button clicked!');};

  const [selectedValue, setSelectedValue] = useState('');

  const [message, setMessage] = useState("");

  const menuItems = [
    { label: 'Home', dropdown: false },
    { label: 'Services', dropdown: true },
    { label: 'About', dropdown: false },
    { label: 'Contact', dropdown: false }
  ];

  const handleMenuClick = (item) => {
    console.log(`${item.label} clicked`);
  };

  const breadcrumbs = [
    { label: "Settings", url: "/", current: false },
    { label: "...", url: "/library", current: false },
    { label: "Data", url: "/data", current: true }
  ];

  const options = [
    { value: 'option 1', label: 'Optie 1' },
    { value: 'option 2', label: 'Optie 2' },
    { value: 'option 3', label: 'Optie 3' },
    { value: 'option 4', label: 'Optie 4' },
    { value: 'option 5', label: 'Optie 5' },
    { value: 'option 6', label: 'Optie 6' },
    { value: 'option 7', label: 'Optie 7' },
    { value: 'option 8', label: 'Optie 8' },
    { value: 'option 9', label: 'Optie 9' },
  ];

  const breadcrumbPaths = [
    { label: 'Settings', current: false },
    { label: '...', current: false },
    { label: 'Team', current: true }
  ];

  const tabsData = [
    { key: 'tab1', label: 'My details' },
    { key: 'tab2', label: 'Profile' },
    { key: 'tab3', label: 'Password' },
    { key: 'tab4', label: 'Team' },
  ];


  

  
  const rowsEmpty = [];

  const rows = [
    {
      title: 'Voorbeeld titel 1',
      subtitle: 'Dit is een voorbeeldsubtitel.',
        url: '/woningtypes/1',
      amount: 5,
      tableTitle2: 'Extra info',
      date: 'Jan 18, 2024',
      linkText: 'Bekijk overeenkomst',
      status: 'success',
      onDelete: (id) => console.log(`Delete row with id: ${id}`)
    },
    {
      title: 'Voorbeeld titel 2',
      subtitle: 'Dit is een voorbeeldsubtitel.',
      amount: 1,
      tableTitle2: 'Extra info',
      date: 'Jan 30, 2024',
      linkText: 'Bekijk overeenkomst',
      status: 'needed',
      onDelete: (id) => console.log(`Delete row with id: ${id}`)
    },
    {
      title: 'Voorbeeld titel 3',
      subtitle: 'Dit is een voorbeeldsubtitel.',
      amount: 9,
      tableTitle2: 'Extra info',
      date: 'Jan 4, 2024',
      linkText: 'Bekijk overeenkomst',
      status: 'unsuccessful',
      onDelete: (id) => console.log(`Delete row with id: ${id}`)
    },
    {
      title: 'Voorbeeld titel 4',
      subtitle: 'Dit is een voorbeeldsubtitel.',
      amount: 115,
      tableTitle2: 'Extra info',
      date: 'Jan 4, 2024',
      linkText: 'Bekijk overeenkomst',
      status: 'success',
      onDelete: (id) => console.log(`Delete row with id: ${id}`)
    },
  ];
 



  
  return (
    
    <div className="App">
      







      <div className="App">
      <h1>User Registration</h1>
      <RegisterForm />
    </div>



<div className="App">
      <h1>Welcome to the Login Test</h1>
      <LoginTest />
    </div>

  {/* Zonder content */}
  <Table rows={rowsEmpty} />

{/* Met content */}
<Table rows={rows} />

{/* Error status */}
<Table rows={[]} error={true} />


<Table rows={rows} isBasic={true} />

<Table rows={rows} isBasic={true} headerTitle="Woningtypes" />




<div>
      {/* Badge 1 */}
      <Badge 
        label="Badge 1" 
        backgroundColor="#EEF4FF" 
        borderColor="#C7D7FE" 
        textColor="#3538CD" 
      />
      
      {/* Badge 2 */}
      <Badge 
        label="Badge 2" 
        backgroundColor="#FEF3F2" 
        borderColor="#FECDCA" 
        textColor="#B42318" 
      />
    </div>

<div>
      {/* Je andere componenten */}
      <Footer />
    </div>

<div> 
      <HeaderNavigation menuItems={menuItems} onMenuClick={handleMenuClick} />
      {/* Rest van je pagina */}
    </div>


<div>
<PageHeader
        title="Data Management"
        supportingText="Manage and organize your data files here."
        breadcrumbs={breadcrumbs}
        buttonActions={{
          settings: () => console.log("Settings clicked"),
          addNew: () => console.log("Add new clicked")
        }}
      />
      {/* Andere componenten en content hier */}
    </div>

    <div>
      <p className={styles.displayXsBold}>Horizontal Tabs Variant 1</p>
      <HorizontalTabs tabs={tabsData} variant="variant1" />
      <p className={styles.displayXsBold}>Horizontal Tabs Variant 1</p>
      <HorizontalTabs tabs={tabsData} variant="variant2" />
    </div>




<div>
<p className={styles.displayXsBold}>Breadcrumb</p>
<div className="App">
      <Breadcrumb paths={breadcrumbPaths} />
    </div>
</div>


<div>
<p className={styles.displayXsBold}>TextAreaField</p>
<TextAreaField
        label="Standaard Input"
        hint="Dit is een standaard textarea."
        placeholder="Typ hier iets..."
      />

<TextAreaField
        label="Input met Fout"
        hint="Er is iets misgegaan."
        error="Er is een fout opgetreden."
        placeholder="Foutieve invoer..."
      />

<TextAreaField
        label="Disabled Input"
        hint="Dit veld is uitgeschakeld."
        placeholder="Kan niet typen..."
        disabled
      />


</div>

<div>
<p className={styles.displayXsBold}>InputField</p>
      <InputField
        label="Gebruikersnaam"
        hint="Vul je gebruikersnaam in."
        placeholder="JohnDoe123"
      />
      <InputField
        label="E-mailadres"
        hint="Vul je e-mailadres in."
        placeholder="voorbeeld@domain.com"
        error="Dit e-mailadres is ongeldig." 
      />
      <InputField
        label="Wachtwoord"
        hint="Kies een veilig wachtwoord."
        placeholder="••••••••"
        help={true} 
      />
      <InputField
        label="Commentaar"
        hint="Laat je opmerkingen achter."
        placeholder="Typ hier..."
        disabled={true}
      />
</div>

<div>
<p className={styles.displayXsBold}>Searchfield</p>
<SearchField
  label="Zoeken"
  placeholder="Zoekterm invoeren"
  hint="Voer zoektermen in om te beginnen met zoeken"
/>
</div>




<p className={styles.displayXsBold}>Dropdown</p>
<div>
<Dropdown
    label="Team member"
    options={options} // Zorg dat deze array gedefinieerd is
    hint="This is a hint text to help user."
    placeholder="Select team member" // Eigen placeholder tekst
/>

     
    </div>



      <p className={styles.displayXsBold}>Notifications/Alerts</p>


      <Alert
        type="info"
        title="Lorem ipsum dolor sit amet"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac lacinia ipsum. Nunc vitae lacinia risus, ac euismod elit. Pellentesque vel lectus non turpis tincidunt tempus."
        linkText="Meer lezen"
        linkUrl="https://www.google.nl" // Dit is de URL die geopend zal worden
        onClick={handleClick}
      />
    <p>&nbsp;</p>
      <Alert 
        type="warning"
        title="Waarschuwing"
        description="Dit is een waarschuwingsbericht om voorzichtig te zijn met de volgende stappen."
        linkText="Actie ondernemen"
        linkUrl="/warning"
      />
      <p>&nbsp;</p>
      <Alert 
        type="error"
        title="Foutmelding"
        description="Er is een fout opgetreden. Neem contact op met de support als het probleem blijft voortduren."
        linkText="Contact Support"
        linkUrl="/support"
      />
      <p>&nbsp;</p>
      <Alert 
        type="success"
        title="Succesvol Voltooid"
        description="Je actie is succesvol verwerkt en opgeslagen in ons systeem."
        linkText="Bekijk details"
        linkUrl="/details"
      />
  

      
      <p className={styles.displayXsBold}>Checkboxes</p>
      <div className="section">
        <Checkbox
          label="Accepteer voorwaarden"
          checked={termsChecked}
          onChange={() => setTermsChecked(!termsChecked)}
          description="Door deze optie te selecteren, ga je akkoord met de voorwaarden en condities."
        />
        <Checkbox
          label="Ontvang nieuwsbrief"
          checked={newsletterChecked}
          onChange={() => setNewsletterChecked(!newsletterChecked)}
          description="Ontvang updates over nieuwe producten en aanbiedingen."
        />
        <Checkbox
          label="Schakel meldingen in"
          checked={notificationsChecked}
          onChange={() => setNotificationsChecked(!notificationsChecked)}
          description="Sta meldingen toe voor belangrijke activiteiten."
        />
      </div>

      <p>&nbsp;</p>

      <div className="App">
      <p className={styles.displayXsBold}>Radiobutton</p>
      <RadioButton
        label="Optie 1"
        checked={selectedOption === 'option1'}
        onChange={() => setSelectedOption('option1')}
        value="option1"
        name="group1"
        description="Beschrijving voor Optie 1"
      />
      <RadioButton
        label="Optie 2"
        checked={selectedOption === 'option2'}
        onChange={() => setSelectedOption('option2')}
        value="option2"
        name="group1"
        description="Beschrijving voor Optie 2"
      />
      <RadioButton
        label="Optie 3"
        checked={selectedOption === 'option3'}
        onChange={() => setSelectedOption('option3')}
        value="option3"
        name="group1"
        description="Beschrijving voor Optie 3"
      />
    </div>




      <div className="App">

        <p className={styles.displayXsBold}>Primary</p>

          <div className="button-row">
            <ButtonWithIcon iconName="circle" iconPosition="both" className="buttonPrimaryXL">Button CTA</ButtonWithIcon>
            <ButtonWithIcon iconName="circle" iconPosition="both" className="buttonPrimary2XL">Button CTA</ButtonWithIcon>
          </div>

          <p className={styles.displayXsBold}>Secondary Gray</p>

          <div className="button-row">
            <ButtonWithIcon iconName="circle" iconPosition="both" className="buttonSecondaryGrayXL">Button CTA</ButtonWithIcon>
            <ButtonWithIcon iconName="circle" iconPosition="both" className="buttonSecondaryGray2XL">Button CTA</ButtonWithIcon>
          </div>

          <p className={styles.displayXsBold}>Tetiary Gray</p>

          <div className="button-row">
          <ButtonWithIcon iconName="circle" iconPosition="both" className="buttonTetiaryGrayXL">Button CTA</ButtonWithIcon>
          <ButtonWithIcon iconName="circle" iconPosition="both" className="buttonTetiaryGray2XL">Button CTA</ButtonWithIcon>
          </div>
          
          <p className={styles.displayXsBold}>Tetiary Color</p>
          
          <div className="button-row">
          <ButtonWithIcon iconName="circle" iconPosition="both" className="buttonTetiaryColorXL">Button CTA</ButtonWithIcon>
          <ButtonWithIcon iconName="circle" iconPosition="both" className="buttonTetiaryColor2XL">Button CTA</ButtonWithIcon>
          </div>
          
          <p className={styles.displayXsBold}>Link Color</p>

          <div className="button-row">
          <ButtonWithIcon iconName="circle" iconPosition="both" className="buttonLinkColorXL">Button CTA</ButtonWithIcon>
          <ButtonWithIcon iconName="circle" iconPosition="both" className="buttonLinkColor2XL">Button CTA</ButtonWithIcon>
          </div>
      </div>



      <div className='Sample'>
        <p className={styles.displayXsBold}>Display 2xl</p>
        <p className={styles.display2XlRegular}>Regular</p> 
        <p className={styles.display2XlMedium}>Medium</p>
        <p className={styles.display2XlSemibold}>Semibold</p> 
        <p className={styles.display2XlBold}>Bold</p>
      </div>

      <div className='Sample'>
        <p className={styles.displayXsBold}>Display xl</p>
        <p className={styles.displayXlRegular}>Regular</p> 
        <p className={styles.displayXlMedium}>Medium</p>
        <p className={styles.displayXlSemibold}>Semibold</p> 
        <p className={styles.displayXlBold}>Bold</p>
      </div>

      <div className='Sample'>
        <p className={styles.displayXsBold}>Display lg</p>
        <p className={styles.displayLgRegular}>Regular</p> 
        <p className={styles.displayLgMedium}>Medium</p>
        <p className={styles.displayLgSemibold}>Semibold</p> 
        <p className={styles.displayLgBold}>Bold</p>
      </div>

      <div className='Sample'>
        <p className={styles.displayXsBold}>Display md</p>
        <p className={styles.displayMdRegular}>Regular</p> 
        <p className={styles.displayMdMedium}>Medium</p>
        <p className={styles.displayMdSemibold}>Semibold</p> 
        <p className={styles.displayMdBold}>Bold</p>
      </div>
      
      <div className='Sample'>
        <p className={styles.displayXsBold}>Display sm</p>
        <p className={styles.displaySmRegular}>Regular</p> 
        <p className={styles.displaySmMedium}>Medium</p>
        <p className={styles.displaySmSemibold}>Semibold</p> 
        <p className={styles.displaySmBold}>Bold</p>
      </div>

      <div className='Sample'>
        <p className={styles.displayXsBold}>Display xs</p>
        <p className={styles.displayXsRegular}>Regular</p> 
        <p className={styles.displayXsMedium}>Medium</p>
        <p className={styles.displayXsSemibold}>Semibold</p> 
        <p className={styles.displayXsBold}>Bold</p>
      </div>
      
      <div className='Sample'>
        <p className={styles.displayXsBold}>Text xl</p>
        <p className={styles.textXlRegular}>Regular</p> 
        <p className={styles.textXlMedium}>Medium</p>
        <p className={styles.textXlSemibold}>Semibold</p> 
        <p className={styles.textXlBold}>Bold</p>
      </div>

      <div className='Sample'>
        <p className={styles.displayXsBold}>Text lg</p>
        <p className={styles.textLgRegular}>Regular</p> 
        <p className={styles.textLgMedium}>Medium</p>
        <p className={styles.textLgSemibold}>Semibold</p> 
        <p className={styles.textLgBold}>Bold</p>
      </div>

      <div className='Sample'>
        <p className={styles.displayXsBold}>Text md</p>
        <p className={styles.textMdRegular}>Regular</p> 
        <p className={styles.textMdMedium}>Medium</p>
        <p className={styles.textMdSemibold}>Semibold</p> 
        <p className={styles.textMdBold}>Bold</p>
      </div>

      <div className='Sample'>
        <p className={styles.displayXsBold}>Text sm</p>
        <p className={styles.textSmRegular}>Regular</p> 
        <p className={styles.textSmMedium}>Medium</p>
        <p className={styles.textSmSemibold}>Semibold</p> 
        <p className={styles.textSmBold}>Bold</p>
      </div>

      <div className='Sample'>
        <p className={styles.displayXsBold}>Text xs</p>
        <p className={styles.textXsRegular}>Regular</p> 
        <p className={styles.textXsMedium}>Medium</p>
        <p className={styles.textXsSemibold}>Semibold</p> 
        <p className={styles.textXsBold}>Bold</p>
      </div>


    </div>

  );
}

export default App;
