import React from 'react';
import styles from './Checkbox.module.css'; 
import style from '../Globals/Styles.module.css'; 

const Checkbox = ({ label, checked, onChange, description, disabled, labelStyle, descriptionStyle }) => {
  return (
    <div>
      <label className={style.textMdMedium}>
        <input 
          type="checkbox" 
          className={styles.checkboxInput}
          checked={checked} 
          onChange={onChange}
          disabled={disabled}
        />
        <span className={styles.checkmark}></span>
        <span className={`${style[labelStyle]}`}>{label}</span>
      </label>
      {description && <div className={`${style.textMdRegular} ${styles.checkboxDescription}`}>{description}</div>}
    </div>
  );
};

export default Checkbox;
