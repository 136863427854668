import React from 'react';
import styles from './Alert.module.css';
import { ReactComponent as InfoIcon } from '../../icons/info-circle.svg';
import { ReactComponent as SuccessIcon } from '../../icons/check-circle.svg';
import style from '../Globals/Styles.module.css';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';

const iconTypes = {
  info: InfoIcon,
  warning: InfoIcon,
  error: InfoIcon,
  success: SuccessIcon
};

const Alert = ({ type = 'info', title, description, linkText, linkUrl, onClick }) => {
  const Icon = iconTypes[type];
  const iconBgColor = type === 'info' ? 'var(--color-info-icon)' : 'var(--color-other-icon)';
  const backgroundColor = `var(--color-${type})`;

  return (
    <div className={styles.alert}>
      <div className={styles.icon} style={{ backgroundColor: backgroundColor, color: iconBgColor }}>
        <Icon />
      </div>
      <div className={styles.content}>
        <p className={style.textSmSemibold}>{title}</p>
        <p className={style.textSmRegular}>{description}</p>
        {linkText && (
          <ButtonWithIcon 
            iconName="right"
            iconPosition="left"
            className="alertLink"
            onClick={(e) => {
              e.preventDefault();
              onClick();
              if (linkUrl) window.location.href = linkUrl;
            }}>
            {linkText}
          </ButtonWithIcon>
        )}
      </div>
    </div>
  );
};


export default Alert;
