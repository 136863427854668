import React from 'react';
import { useNavigate } from 'react-router-dom';
import BasePage from './BasePage';
import PageHeader from '../components/PageHeader/PageHeader';
import ButtonWithIcon from '../components/Buttons/ButtonWithIcon';
import Dropdown from '../components/Input/Dropdown';
import InputField from '../components/Input/InputField';
import Alert from '../components/Feedback/Alert';
import styles from './NieuwVerkoopovereenkomstPage.module.css';
import style from '../components/Globals/Styles.module.css';
import FileUpload from '../components/FileUpload/FileUpload';
import ProgressSteps from '../components/ProgressSteps/ProgressSteps';

const NieuwVerkoopovereenkomstPage = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    { label: 'Verkoopcontracten', path: '/verkoopcontracten' },
    { label: 'Nieuw verkoopovereenkomst', path: '/verkoopcontracten/nieuw' },
  ];

  const handleClick = () => {
    console.log('Alert link clicked!');
  };

  const opdrachtOptions = [
    { value: 'opdracht1', label: 'Verkoopovereenkomst 2024' },
    { value: 'opdracht2', label: 'Verkoopovereenkomst 2023' },
  ];

  const woningtypeOptions = [
    { value: 'woningtype1', label: 'Woningtype A' },
    { value: 'woningtype2', label: 'Woningtype B' },
  ];

  const steps = ['Gegevens invullen', 'Gegevens controleren'];
  const currentStep = 0;

  const handleNextStep = () => {
    navigate('/verkoopcontracten/nieuw/controle');
  };

  const handleGoBack = () => {
    navigate('/verkoopcontracten/nieuw'); // Of gebruik navigate(-1) voor de vorige pagina
  };

  const handleSubmit = () => {
    // Voer hier submit functionaliteit uit
    console.log("Formulier verstuurd");
    navigate('/verkoopcontracten'); // Na het opslaan, navigeer terug naar de hoofdpagina
  };

  return (
    <BasePage backgroundColor="#F9FAFB">
      <PageHeader
        title="Nieuw verkoopovereenkomst"
        breadcrumbs={breadcrumbs}
        variant="basic"
        showTabs={false}
        showDivider={false}
        extraBottomPadding={true}
      />

      <div className={styles.stepsContainer}>
        <ProgressSteps steps={steps} currentStep={currentStep} />
      </div>

      {/* Blok 1 met formulier type inhoud */}
      <div className={styles.formBlock}>
        <h2 className={style.textXlSemibold}>Instructies</h2>
        <p className={style.textSmRegular}>Vul hieronder de gegevens in voor uw verkoopovereenkomst.</p>
        
        <ButtonWithIcon 
          iconName="right" 
          iconPosition="left" 
          className="alertLink" 
          onClick={() => console.log('Link geklikt!')}
        >
          Nog vragen? Kijk hier voor meer informatie
        </ButtonWithIcon>
      </div>

      {/* Blok 2: Overeenkomstgegevens */}
      <div className={styles.formBlock}>
        <h2 className={style.textXlSemibold}>Overeenkomstgegevens</h2>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Opdrachtgever</label>
          </div>
          <div className={styles.inputColumn}>
            <Dropdown
              label="Met welke opdrachtgever wil u dit project aangaan?"
              options={opdrachtOptions}
              placeholder="Kies een opdrachtgever"
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div> {/* Leeg voor de Alert component */}
          <div className={styles.inputColumn}>
            <Alert
              type="info"
              title="Kunt u een contact niet vinden?"
              description="Als het contact waar u naar opzoek bent niet in de lijst staat, mogelijk heeft het contact nog geen toegang tot de DataMarketPlace. Nodig uw contact uit om deel te nemen door het invullen van hun gegevens."
              linkText="Nieuwe contact aanmelden"
              linkUrl="https://marlin-scx.com/"
              onClick={handleClick}
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Woningtype</label>
          </div>
          <div className={styles.inputColumn}>
            <Dropdown
              label="Welk woningtype wil u opnemen in het project?"
              options={woningtypeOptions}
              placeholder="Kies een woningtype"
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Aantal woningen</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Om hoeveel woningen gaat het in totaal?" 
              placeholder="" 
            />
          </div>
        </div>
      </div>

      {/* Blok 3: Opdracht informatie */}
      <div className={styles.formBlock}>
        <h2 className={style.textXlSemibold}>Opdracht informatie</h2>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Opdracht naam</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Wat is de naam van uw opdracht?" 
              placeholder=" " 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Opdracht nummer</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Wat is het referentie nummer van de opdracht?" 
              placeholder=" " 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Budget</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Wat is het totale budget?" 
              placeholder="€ 0,00" 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Startdatum</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Wanneer start de opdracht?" 
              placeholder=" " 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Verwachte opleverdatum</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Wanneer wordt de opdracht opgeleverd?" 
              placeholder=" " 
            />
          </div>
        </div>
      </div>

      {/* Blok 4: Beheerder informatie (optioneel) */}
      <div className={styles.formBlock}>
        <h2 className={style.textXlSemibold}>Beheerder informatie (optioneel)</h2>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Beheerder</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Beheerder informatie (optioneel)" 
              placeholder="" 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Contactpersoon</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Wie is uw contactpersoon?" 
              placeholder="" 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Adres</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Straatnaam" 
              placeholder="" 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div> {/* Leeg voor de 3 inputvelden */}
          <div className={styles.inputColumn}>
            <div className={styles.inputGroup}>
              <InputField label="Postcode" placeholder="" />
              <InputField label="Huisnummer" placeholder="" />
              <InputField label="Toevoeging" placeholder="" />
            </div>
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField label="Stad" placeholder="" />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField label="Land" placeholder="" />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Telefoonnummer</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Wat is het telefoonnummer?" 
              placeholder="" 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>E-mail</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Wat is de email?" 
              placeholder="" 
            />
          </div>
        </div>
      </div>

      {/* Blok 5: Bouwlocatie van project */}
      <div className={styles.formBlock}>
        <h2 className={style.textXlSemibold}>Bouwlocatie van project</h2>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Bedrijfsnaam</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Wat is de naam van het bedrijf?" 
              placeholder="" 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Adres</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Straatnaam" 
              placeholder="" 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div> {/* Leeg voor de 3 inputvelden */}
          <div className={styles.inputColumn}>
            <div className={styles.inputGroup}>
              <InputField label="Postcode" placeholder="" />
              <InputField label="Huisnummer" placeholder="" />
              <InputField label="Toevoeging" placeholder="" />
            </div>
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField label="Stad" placeholder="" />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField label="Land" placeholder="" />
          </div>
        </div>
      </div>

      {/* Blok 6: Assemblage locatie */}
      <div className={styles.formBlock}>
        <h2 className={style.textXlSemibold}>Assemblage locatie</h2>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Bedrijfsnaam</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Wat is de naam van het bedrijf?" 
              placeholder="" 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Adres</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="Straatnaam" 
              placeholder="" 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div> {/* Leeg voor de 3 inputvelden */}
          <div className={styles.inputColumn}>
            <div className={styles.inputGroup}>
              <InputField label="Postcode" placeholder="" />
              <InputField label="Huisnummer" placeholder="" />
              <InputField label="Toevoeging" placeholder="" />
            </div>
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField label="Stad" placeholder="" />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}></div>
          <div className={styles.inputColumn}>
            <InputField label="Land" placeholder="" />
          </div>
        </div>
      </div>

      {/* Blok 7: Woningspecificaties */}
      <div className={styles.formBlock}>
        <h2 className={style.textXlSemibold}>Woningspecificaties</h2>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Optie voor woning</label>
          </div>
          <div className={styles.inputColumn}>
            <Dropdown
              label="Welke optie kiest de opdrachtgever voor de woning?"
              options={woningtypeOptions} 
              placeholder="Kies een optie"
            />
          </div>
        </div>
      </div>

      {/* Blok 8: Overige informatie */}
      <div className={styles.formBlock}>
        <h2 className={style.textXlSemibold}>Overige informatie</h2>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Opmerkingen</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="" 
              placeholder="" 
            />
          </div>
        </div>

        <div className={styles.inputRow}>
          <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Risicoanalyse</label>
          </div>
          <div className={styles.inputColumn}>
            <InputField 
              label="" 
              placeholder="" 
            />
          </div>
        </div>
      </div>






      {/* Blok 9: Documenten */}



<div className={styles.formBlock}>
    <h2 className={style.textXlSemibold}>Documenten</h2>

    <div className={styles.inputRow}>
        <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Projectplan</label>
        </div>
        <div className={styles.inputColumn}>
            <FileUpload onUpload={(files) => console.log("Projectplan bestanden:", files)} />
        </div>
    </div>

    <div className={styles.inputRow}>
        <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Bouwplan</label>
        </div>
        <div className={styles.inputColumn}>
            <FileUpload onUpload={(files) => console.log("Bouwplan bestanden:", files)} />
        </div>
    </div>

    <div className={styles.inputRow}>
        <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Vergunningen</label>
        </div>
        <div className={styles.inputColumn}>
            <FileUpload onUpload={(files) => console.log("Vergunningen bestanden:", files)} />
        </div>
    </div>

    <div className={styles.inputRow}>
        <div className={styles.labelColumn}>
            <label className={style.textMdSemibold}>Risicoanalyse</label>
        </div>
        <div className={styles.inputColumn}>
            <FileUpload onUpload={(files) => console.log("Risicoanalyse bestanden:", files)} />
        </div>
    </div>

</div>

{/* Knop rechts uitgelijnd onder het laatste blok */}
<div className={styles.buttonContainer}>
<ButtonWithIcon
          className="buttonPrimary2XL"
          onClick={handleNextStep} // Voeg de onClick toe
        >
          Verder
        </ButtonWithIcon>
</div>

    </BasePage>
  );
};

export default NieuwVerkoopovereenkomstPage;
