import React from 'react';
import styles from './SearchFilterBar.module.css';
import SearchField from '../Input/SearchField';
import Dropdown from '../Input/Dropdown';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import RadioButton from '../Input/RadioButton';

const SearchFilterBar = ({ 
  onSearch, 
  searchPlaceholder = "Zoeken", 
  sortOptions = [], 
  onSort,
  showRadioButtons = false,   // Bepaal of radiobuttons worden getoond
  radioOptions = [],          // Radiobutton opties (labels)
  selectedRadio = "",         // Geselecteerde radiobutton
  onRadioChange               // Functie om radiobuttons te beheren
}) => {
  return (
    <div className={styles.searchFilterBar}>
      {/* Container voor zoekbalk en knoppen naast elkaar */}
      <div className={styles.topContent}>
        <div className={styles.leftContent}>
          <SearchField 
            placeholder={searchPlaceholder} 
            onSearch={onSearch}  // Geef de onSearch-functie door aan SearchField
          />
        </div>
        <div className={styles.rightContent}>
          {/* Sorteren dropdown */}
          <Dropdown
            label=""
            options={sortOptions}
            placeholder="Sorteren op..."
            onChange={onSort}
          />
          {/* Filter knop */}
          <ButtonWithIcon className="buttonSecondaryGrayXL" iconName="filter" iconPosition="left">
            Filteren
          </ButtonWithIcon>
        </div>
      </div>

      {/* Radiobuttons onder de zoekbalk */}
      {showRadioButtons && (
        <div className={styles.radioButtonsContainer}>
          {radioOptions.map((option) => (
            <RadioButton
              key={option.value}
              label={option.label}
              checked={selectedRadio === option.value}
              onChange={() => onRadioChange(option.value)}
              value={option.value}
              name="searchRadio"
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchFilterBar;
