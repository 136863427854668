import React from 'react';
import styles from './Badge.module.css';
import style from '../Globals/Styles.module.css';

const badgeStyles = {
  success: {
    backgroundColor: 'var(--color-success-50)',  
    borderColor: 'var(--color-success-200)',   
    textColor: 'var(--color-success-700)',   
    text: 'Gecontracteerd',
  },
  needed: {
    backgroundColor: 'var(--color-gray-200)',    
    borderColor: 'var(--color-brand-200)', 
    textColor: 'var(--color-brand-700)',
    text: 'Wachten op ondertekening',
  },
  unsuccessful: {
    backgroundColor: 'var(--color-error-50)', 
    borderColor: 'var(--color-error-200)',    
    textColor: 'var(--color-error-700)',      
    text: 'Actie niet succesvol',
  },
};

const Badge = ({ status }) => {
  const { backgroundColor, borderColor, textColor, text } = badgeStyles[status] || {};
  
  return (
    <span 
      className={`${styles.badge} ${style.textXsMedium}`} 
      style={{ backgroundColor, borderColor, color: textColor }}
    >
      {text}
    </span>
  );
};

export default Badge;
