import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderNavigation from '../components/HeaderNavigation/HeaderNavigation';
import Footer from '../components/Footer/Footer'; 
import styles from './BasePage.module.css'; 

const BasePage = ({ children, backgroundColor }) => {
  const navigate = useNavigate();

  const handleMenuClick = (item) => {
    switch (item.label) {
      case 'Mijn account':
        navigate('/account'); 
        break;
      case 'Mijn woningtypes':
        navigate('/woningtypes');
        break;
      case 'Mijn verkoopcontracten':
        navigate('/verkoopcontracten');
        break;
      case 'Mijn leveringsovereenkomsten':
        navigate('/leveringsovereenkomsten');
        break;
      case 'Service & contact':
        navigate('/contact');
        break;
      default:
        navigate('/');
    }
  };

  // Als `children` geen array is, maak er een array van
  const childrenArray = React.Children.toArray(children);

  return (
    <div className={styles.pageContainer}>
      <HeaderNavigation 
        menuItems={[
          { label: 'Mijn account' },
          { label: 'Mijn woningtypes' },
          { label: 'Mijn verkoopcontracten' },
          { label: 'Mijn leveringsovereenkomsten' },
          { label: 'Service & contact' }
        ]}
        onMenuClick={handleMenuClick} 
      />
      
      {/* Witte achtergrond voor de PageHeader */}
      <div className={styles.headerWrapper}>
        {/* Het eerste kind van `childrenArray` is de PageHeader */}
        {childrenArray[0]}
      </div>

      {/* Achtergrondkleur voor alles onder de PageHeader */}
      <div className={styles.backgroundWrapper} style={{ backgroundColor: backgroundColor || 'inherit' }}>
        {/* Alle andere elementen onder de PageHeader */}
        <main className={styles.mainContent}>
          {childrenArray.slice(1)} {/* Render de rest van de kinderen */}
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default BasePage;
