import React from 'react';
import { Link } from 'react-router-dom';  // Importeer de Link component
import styles from './HeaderNavigation.module.css';
import style from '../Globals/Styles.module.css';
import logo from '../../assets/images/LogoHeader.webp';
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down.svg';
import { ReactComponent as HomeIcon } from '../../icons/home-line.svg';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';

const HeaderNavigation = ({ menuItems, onMenuClick }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {/* Maak het logo klikbaar door het te omringen met een Link */}
        <Link to="/">
          <img src={logo} alt="Go to Homepage" className={styles.logo} />
        </Link>
        <div className={styles.buttonGroup}>
          <ButtonWithIcon className="buttonTetiaryGrayXL">Log in</ButtonWithIcon>
          <ButtonWithIcon className="buttonPrimaryXL">Sign up</ButtonWithIcon>
        </div>
      </div>
      <div className={styles.fullWidthLine}></div> {/* Lijn tussen logo-container en navigatiebalk */}
      <div className={styles.navBar}>
        <nav className={styles.navMenu}>
          {menuItems.map((item, index) => (
            <ButtonWithIcon
              key={index}
              className="buttonTetiaryGrayXL"
              onClick={() => onMenuClick(item)}
            >
              {item.label}
            </ButtonWithIcon>
          ))}
        </nav>
      </div>
      <div className={styles.fullWidthLine}></div> {/* Onderste lijn over volledige breedte */}
    </div>
  );
};

export default HeaderNavigation;
