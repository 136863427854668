import React from 'react';
import BasePage from './BasePage';
import PageHeader from '../components/PageHeader/PageHeader';

const OvereenkomstenPage = () => {

  const breadcrumbs = [
    { label: 'Mijn leveringsovereenkomsten', path: '/leveringsovereenkomsten' }
  ];

  return (
    <BasePage>
     <PageHeader 
        title="Mijn leveringsovereenkomsten" 
        supportingText="U ziet hier mijn leveringsovereenkomsten."
        breadcrumbs={breadcrumbs} 
        variant="basic"
        showTabs={false}
        showDivider={false}  
      />

    </BasePage>
  );
};

export default OvereenkomstenPage;