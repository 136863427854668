import React from 'react';
import { useNavigate } from 'react-router-dom';
import BasePage from './BasePage';
import PageHeader from '../components/PageHeader/PageHeader';
import ButtonWithIcon from '../components/Buttons/ButtonWithIcon';

const SuccessPage = () => {
  const navigate = useNavigate();

  const handleGoToOverview = () => {
    navigate('/verkoopcontracten');
  };

  return (
    <BasePage>
      <PageHeader 
        variant="basic"
        title="Verkoopcontract succesvol opgeslagen" 
        supportingText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nibh diam, aliquet et condimentum sed, faucibus nec est."
        showTabs={false}
        showDivider={false}
        showBreadcrumb={false}
        extraBottomPadding={true}
      />
      <div>
        <ButtonWithIcon 
          iconName="right" 
          iconPosition="left" 
          className="alertLink"
          onClick={handleGoToOverview}
        >
          Terug naar overzicht
        </ButtonWithIcon>
      </div>
    </BasePage>

  );
};

export default SuccessPage;
