import React, { useEffect, useState } from 'react';
import axios from 'axios';

const APITestPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Mock data
  const mockData = [
    { id: '1', name: 'Mock Woningtype A', referentienummer: 'REF-001' },
    { id: '2', name: 'Mock Woningtype B', referentienummer: 'REF-002' },
    { id: '3', name: 'Mock Woningtype C', referentienummer: 'REF-003' },
  ];

  // Toggle mock data
  const isMockEnabled = true;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (isMockEnabled) {
          // Simuleer mock data
          console.log('Using mock data');
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Simuleer vertraging
          setData(mockData); // Gebruik mock data
        } else {
          // Haal echte data van de API op
          const response = await axios.get('/api/v1/assetmanager/woningtypes/get_my', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          setData(response.data);
        }
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isMockEnabled]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h1>Woningtypes</h1>
      <ul>
        {data.map((woningtype) => (
          <li key={woningtype.id}>
            {woningtype.name} - {woningtype.referentienummer}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default APITestPage;