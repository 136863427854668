import React from 'react';
import BasePage from './BasePage';
import PageHeader from '../components/PageHeader/PageHeader';

const HomePage = () => {

  const breadcrumbs = [
    { label: 'Home', path: '/' }
  ];

  return (
    <BasePage>
     <PageHeader 
        title="Home" 
        supportingText="U ziet hier homepage."
        breadcrumbs={breadcrumbs} 
        variant="basic"
        showTabs={false}
        showDivider={false}  
      />

    </BasePage>
  );
};

export default HomePage;