import React from 'react';
import './RadioButton.css';

const RadioButton = ({ label, checked, onChange, value, name, description }) => {
  return (
    <label className="radio-container">
      {label}
      <input
        type="radio"
        checked={checked}
        onChange={onChange}
        value={value}
        name={name}
      />
      <span className="radio-checkmark"></span>
      {description && <div className="radio-description">{description}</div>}
    </label>
  );
};

export default RadioButton;